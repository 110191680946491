import React from 'react';
import Seo from 'components/seo';
import ErrorSec from 'containers/Error';
import { ResetCSS } from 'common/assets/css/style';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => (
  <>
    <Seo title="404: Página no encontrada" />
    <Helmet>
      <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/a370565f2585f5f2f12fe533/script.js"/>
    </Helmet>
    <ResetCSS />
    <ErrorSec></ErrorSec>
  </>
);

export default NotFoundPage;
